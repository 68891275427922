import { Mixpanel } from 'mixpanel-browser';
import {
  AnalyticsEvent,
  AnalyticsEventName,
  Analytics,
} from '../types/AnayticsTypes';
import persistenceService from '@/lib/utils/persistenceService';
import { Applicant } from '@/lib/types/OffersPayload.types';

const analytics = (mixpanel: Mixpanel): Analytics => {
  let tracking = false;
  let eventQueue: AnalyticsEvent[] = [];
  let isUserIdentified = false;

  const trackNewOrReturningUser = (): void => {
    const firstTimeVisitDate = mixpanel.get_property('first_time_visit_date');

    mixpanel.register({
      user_visit: firstTimeVisitDate === undefined ? 'new' : 'returning',
    });

    mixpanel.register_once({
      first_time_visit_date: new Date().toISOString(),
    });
  };

  const identifyUser = () => {
    if (isUserIdentified) return;
    // if falsey, reset?
    // events arent backfilling, why?

    let journeyDetails: any = {};
    try {
      journeyDetails = persistenceService.get('journeyDetails');
    } catch (error) {
      console.error(
        'Analytics: Error parsing journeyDetails from sessionStorage',
        error,
      );
    }
    const merchantId = journeyDetails?.offersPayload?.application.merchant_id;
    if (!merchantId) {
      // If no merchantId is found, continue tracking events anonymously.
      // Mixpanel will default to using the device ID for tracking in this case.
      return;
    }
    const primaryApplicant = journeyDetails?.offersPayload?.applicants?.find((a: Applicant) => a.primary);
    const primaryApplicantEmailAddress = primaryApplicant?.email_address;

    try {
      mixpanel.alias(merchantId);
      mixpanel.identify(merchantId);
      mixpanel.people.set({ "$braze_external_id": merchantId });
      primaryApplicantEmailAddress && mixpanel.people.set({ "$email": primaryApplicantEmailAddress });
      isUserIdentified = true;
    } catch (error) {
      console.warn('Analytics: Failed to identify user', error);
    }
  };

  const enableTracking = () => {
    tracking = true;
    identifyUser();
    sendAnalyticsEvents();
  };

  const sendAnalyticsEvents = (): void => {
    eventQueue.forEach((event) => {
      const propertiesWithTimestamp = {
        ...event.properties,
        time: event.timestamp,
      };
      try {
        mixpanel.track(event.eventName, propertiesWithTimestamp);
      } catch (error) {
        console.error('Error tracking event', event, error);
      }
    });
    eventQueue = [];
  };

  const track = (eventName: string, partner: string, properties = {}): void => {
    const timestamp = new Date().getTime();
    eventQueue.push({
      eventName,
      properties: { ...properties, partner },
      timestamp,
    });

    if (tracking) {
      sendAnalyticsEvents();
    }
  };

  const trackStepViewed = (partner: string, stepName: string) => {
    identifyUser();
    track(AnalyticsEventName.StepViewed, partner, { stepName: stepName });
  };

  const trackButtonClick = (
    partner: string,
    buttonText: string,
    trackingLabel: string,
  ) => {
    identifyUser();
    track(AnalyticsEventName.ButtonClicked, partner, {
      buttonText,
      trackingLabel,
    });
  };

  const trackFieldFilled = (
    partner: string,
    fieldName: string,
    fieldType: string,
  ) => {
    identifyUser();
    track(AnalyticsEventName.FieldFilled, partner, {
      fieldName,
      fieldType,
    });
  };

  const trackSliderRevenueChange = (
    partner: string,
    sliderEvent: string,
    previousAverageMonthlyRevenue: number,
    averageMonthlyRevenue: number,
  ) => {
    identifyUser();
    track(AnalyticsEventName.SliderChanged, partner, {
      sliderEvent,
      previousAverageMonthlyRevenue,
      averageMonthlyRevenue,
    });
  };

  const trackSliderMonthsInBusinessChange = (
    partner: string,
    sliderEvent: string,
    previousMonthsInBusiness: number,
    monthsInBusiness: number,
  ) => {
    identifyUser();
    track(AnalyticsEventName.SliderChanged, partner, {
      sliderEvent,
      previousMonthsInBusiness,
      monthsInBusiness,
    });
  };

  const trackFundingQuoteChange = (
    partner: string,
    fieldType: string,
    startingAmount: number,
    finalAmount: number,
  ) => {
    identifyUser();
    track(AnalyticsEventName.SliderChanged, partner, {
      fieldType,
      startingAmount,
      finalAmount,
    });
  };

  const trackCheckboxClick = (
    partner: string,
    value: boolean,
    checkboxName: string,
  ) => {
    identifyUser();
    track(AnalyticsEventName.CheckboxClicked, partner, {
      checkboxName,
      value,
    });
  };

  const trackTooltipViewed = (
    partner: string,
    payload: {
      text: string;
      fieldName: string;
      tooltipId: string;
    },
  ) => {
    identifyUser();
    track(AnalyticsEventName.TooltipViewed, partner, {
      text: payload.text,
      fieldName: payload.fieldName,
      tooltipId: payload.tooltipId,
    });
  };

  const trackSliderFundingQuoteAmountChange = (
    partner: string,
    sliderEvent: string,
    min: number,
    max: number,
    previousFundingQuoteAmount: number,
    fundingQuoteAmount: number,
  ) => {
    identifyUser();
    track(AnalyticsEventName.QuoteFundingAmountSliderChanged, partner, {
      sliderEvent,
      min,
      max,
      previousFundingQuoteAmount,
      fundingQuoteAmount,
    });
  };

  const trackInputFundingQuoteAmountChange = (
    partner: string,
    inputEvent: string,
    min: number,
    max: number,
    previousFundingQuoteAmount: number,
    fundingQuoteAmount: number,
  ) => {
    identifyUser();
    track(AnalyticsEventName.QuoteFundingAmountInputChanged, partner, {
      inputEvent,
      min,
      max,
      previousFundingQuoteAmount,
      fundingQuoteAmount,
    });
  };

  const trackApplicationDecisionStatus = (partner: string, value: string) => {
    identifyUser();
    track(AnalyticsEventName.ReportApplicationDecisionStatus, partner, {
      value,
    });
  };

  const setUserProperty = (propertyName: string, value: string) => {
    identifyUser();

    if (tracking) {
      mixpanel.people.set({
        [propertyName]: value,
      });
    }
  };

  const getProperty = (propertyName: string) => {
    return mixpanel.get_property(propertyName);
  };

  const trackLoggedIn = (partner: string, email?: string) => {
    track(AnalyticsEventName.LoggedIn, partner, { email, isCoreAce: 'true' });
  };

  const trackRenewalBannerShown = (partner: string) => {
    identifyUser();
    track(AnalyticsEventName.RenewalBannerShown, partner, {
      value: true,
    });
  };

  return Object.freeze({
    trackStepViewed,
    trackNewOrReturningUser,
    enableTracking,
    trackButtonClick,
    trackFieldFilled,
    trackSliderRevenueChange,
    trackSliderMonthsInBusinessChange,
    trackFundingQuoteChange,
    trackCheckboxClick,
    trackTooltipViewed,
    trackSliderFundingQuoteAmountChange,
    trackInputFundingQuoteAmountChange,
    setUserProperty,
    trackApplicationDecisionStatus,
    trackLoggedIn,
    trackRenewalBannerShown,
    getProperty,
  });
};

export default analytics;
